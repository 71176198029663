<template>
  <div id="trending-videos">
    <router-link
      class="flex items-center justify-start p-4 pb-0 space-x-4 select-none"
      to="/trending-videos"
    >
      <span class="image-container">
        <img :src="TrendingIcon" alt="RecentlyAddedIcon" />
      </span>
      <p class="text-lg font-bold m-0 text-black dark:text-white">
        Trending videos
      </p>
    </router-link>
    <div class="video-grid skeleton-grid min-w-[240px]" v-if="loading">
      <VideoSkeleton v-for="n in numberOfSkeletonVideos" :key="n" />
    </div>
    <div class="video-grid min-w-[240px]" v-else>
      <VideoPreview
        v-for="video in videosToShow"
        :key="video.id"
        :video="video"
      />
    </div>
    <div
      v-if="!loading && trendingVideos.length === 0"
      class="text-center h-full"
    >
      No videos
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  ref,
  watch,
  onMounted,
  onBeforeUnmount,
  nextTick,
} from "vue";
import VideoPreview from "@/components/videoPreview/VideoPreview.vue";
import VideoSkeleton from "@/components/VideoSkeleton.vue";
import { apiBackendAuthAxios } from "@/axiosAuth.js";
import TrendingIcon from "@/assets/playtv/icons/trending.svg";
import { transformToVideo } from "@/types/Video.tsx";
import { useSidebarStore } from "@/stores/sidebarStore";

const emits = defineEmits(["loadedVideos"]);
const props = defineProps({
  loading: {
    type: Boolean,
    required: true,
  },
});

const loading = computed(() => {
  return props.loading;
});

const trendingVideos = ref([]);
const videosToShow = ref([]); // Only the videos to display
const numberOfVideos = ref(0);
const numberOfSkeletonVideos = ref(0);
const sidebarStore = useSidebarStore();
const gridWidth = ref(0);

const MAX_ROWS = 2; // Set the number of rows you want
let columns = 1;

const calculateColumns = () => {
  const containerWidth =
    document.querySelector(".video-grid")?.clientWidth || 0;

  // Need to display at least one thumbnail on very small viewport
  columns = Math.max(1, Math.floor(containerWidth / 290));
  numberOfVideos.value = columns * MAX_ROWS;

  if (trendingVideos.value.length > 0) {
    updateVideosToShow();
  }
};

const calculateSkeletonColumns = () => {
  const containerWidth = document.querySelector(".video-grid")?.clientWidth || 0;
  columns = Math.max(1, Math.floor(containerWidth / 290));
  document.documentElement.style.setProperty('--grid-columns', columns);
  numberOfSkeletonVideos.value = columns * 2;
};

const updateVideosToShow = () => {
  const maxVideos = columns * MAX_ROWS;
  videosToShow.value = trendingVideos.value.slice(0, maxVideos);
};

// Observe changes in the grid width and recalculate columns
const observeGridWidth = () => {
  const videoGrid = document.querySelector(".video-grid");
  if (videoGrid) {
    const resizeObserver = new ResizeObserver(() => {
      gridWidth.value = videoGrid.clientWidth;
      if (gridWidth.value === 0) return;
      calculateColumns();
      calculateSkeletonColumns();
    });
    resizeObserver.observe(videoGrid);

    // Clean up observer
    onBeforeUnmount(() => {
      resizeObserver.disconnect();
    });
  }
};

const loadTrendingVideos = async () => {
  try {
    // Fetch the latest videos
    const {
      data: { ulids: videoDataIds },
    } = await apiBackendAuthAxios.get("/trending/videos/last7days");

    if (videoDataIds.length === 0) {
      updateVideosToShow();
      return;
    }

    const {
      data: { data: videoData },
    } = await apiBackendAuthAxios.post(`/posts/map`, {
      data: videoDataIds,
      responseType: "videos",
    });

    const { data } = await apiBackendAuthAxios.post(`/profile`, {
      ulids: videoData.map((v) => v.user.userId),
    });

    // Transform the video data using the user data
    trendingVideos.value = videoDataIds
      .filter((v1) => videoData.filter((v2) => v1 === v2.id).length > 0)
      .map((v1) => {
        return videoData.filter((v2) => v1 === v2.id)[0];
      })
      .map((v) => transformToVideo(v, data[0].data));
    updateVideosToShow();
  } catch (e) {
    console.error(e);
  } finally {
    emits("loadedVideos");
  }
};

// Watch for sidebar state changes and recalculate columns
watch(
  () => sidebarStore.isExpanded(),
  () => {
    nextTick(() => {
      calculateColumns();
    });
  }
);

onMounted(() => {
  observeGridWidth();
  loadTrendingVideos();
});
</script>

<style scoped lang="scss">
.video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 1rem;
  width: 100%;
  overflow-x: auto;
}

.skeleton-grid {
  display: grid;
  grid-template-columns: repeat(var(--grid-columns, 3), 1fr);
  grid-auto-rows: 1fr;
  gap: 1rem;
  width: 100%;
}

#trending-videos {
  width: 100%;
  overflow-x: hidden;
}
</style>
